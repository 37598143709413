<template>
	<div class="app-application">
		<div class="guest-container">
			<div class="header" v-if="store.getters['user/isGeez']">
				<a-button type="primary" @click="addApp">新增应用模板</a-button>
			</div>
			<a-tabs class="top-tabs" v-model:activeKey="activeKey" @change="getDataList(1)">
				<a-tab-pane v-for="(item, key) in appTypeListMapping" :key="key" :tab="item"></a-tab-pane>
			</a-tabs>
			<div class="product-card flex" v-show="activeKey !== 'SERVICE'">
				<a-card class="card-item" size="small" :title="item.name" style="width: 300px" v-for="item in tableData" :key="item.id">
					<template #extra>
						<span v-if="item.isAppCreated" style="color: #6dc134">已添加</span>
						<a v-else @click="goToOauth(item)">添加应用</a>
					</template>
					<p>{{ item.desc }}</p>
				</a-card>
			</div>
			<div class="service-list" v-show="activeKey === 'SERVICE'">
				<div class="table-header mb10">
					<a-form layout="inline" style="margin-top: 10px">
						<a-form-item label="应用名称">
							<a-input v-model:value="appParams.name" placeholder="请输入应用名称" />
						</a-form-item>
						<a-form-item label="应用分类">
							<a-select ref="select" v-model:value="appParams.appCategory" style="width: 120px">
								<a-select-option v-for="item in appTypeList" :key="item.code" :value="item.code">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item>
							<a-button type="primary" @click="searchHandle">查询</a-button>
							<a-button style="margin-left: 10px" @click="reset">重置</a-button>
						</a-form-item>
					</a-form>
				</div>
				<a-table :columns="columns" :pagination="false" :data-source="tableData">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'status'">
							<span>
								{{ record.status === 1 ? "正常" : "禁用" }}
							</span>
						</template>
						<template v-if="column.key === 'icon'">
							<span>
								<img v-if="record.icon" :src="record.icon" style="width: 50px; height: 50px" alt="" />
							</span>
						</template>
						<template v-if="column.key === 'aciton'">
							<a v-if="isShow" @click="editApp(record)"> 编辑 </a>
							<br v-if="isShow" />
							<OauthBtn :record="record" />
						</template>
					</template>
				</a-table>
				<a-pagination
					style="float: right; margin-top: 10px"
					v-model:current="appParams.page_no"
					show-size-changer
					:total="appParams.total"
					:show-total="(total) => `总计 ${total} 条`"
					:page-size="appParams.page_size"
					@change="handleChangePage"
				>
					<template v-slot:buildOptionText="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
			</div>
		</div>
	</div>
	<a-modal v-model:visible="dialogVisible" width="700px" title="应用详情">
		<template #footer>
			<a-button type="primary" @click="changeDiagle">关闭</a-button>
		</template>
		<a-descriptions bordered :column="1" size="small">
			<a-descriptions-item label="应用名称">
				{{ appInfo.name }}
			</a-descriptions-item>
			<a-descriptions-item label="应用ID">
				{{ appInfo.appId }}
			</a-descriptions-item>
			<a-descriptions-item label="应用计划">
				{{ appInfo.name }}
			</a-descriptions-item>
			<a-descriptions-item label="应用计划"> 2018-04-24 18:00:00 </a-descriptions-item>
		</a-descriptions>
	</a-modal>
</template>

<script setup>
import { ref, reactive, computed, onActivated, onMounted } from "vue"
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import OauthBtn from "./components/OauthBtn.vue"
import { useStore } from "vuex"

const store = useStore()
const router = useRouter()

const appTypeList = JSON.parse(localStorage.getItem("appTypeList"))
const loading = ref(false)
const tableData = ref([])
const isShow = ref(false)
const activeKey = ref("PRODUCT")
const dialogVisible = ref(false)

const appParams = reactive({
	name: "",
	appCategory: "",
	total: 23,
	page_no: 1,
	page_size: 20,
	old_page_size: 20,
})

const columns = [
	{ title: "图标", dataIndex: "icon", key: "icon" },
	{ title: "模板名称", dataIndex: "name", key: "name" },
	{ title: "平台", dataIndex: "platform", key: "platform" },
	{ title: "状态", dataIndex: "status", key: "status" },
	{ title: "标签", dataIndex: "tags", key: "tags" },
	{ title: "修改人", dataIndex: "updatedUser", key: "updatedUser" },
	{ title: "修改时间", dataIndex: "updatedAt", key: "updatedAt" },
	{ title: "操作", key: "aciton" },
]

const appInfo = reactive({
	name: "OMS系统",
	appId: "DSADHhkdhsa123hklhodsalhda",
})

const appTypeListMapping = computed(() => store.state.appTypeListMapping)

onActivated(() => {
	getDataList()
})

onMounted(() => {
	const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
	const roleList = userInfo?.user?.roleList
	roleList?.forEach((item) => {
		if (item.role.code === "admin" || item.role.code === "developer") {
			isShow.value = true
		}
	})
})

const searchHandle = () => {
	appParams.page_no = 1
	getDataList()
}

const getDataList = (pageNo) => {
	if (pageNo) {
		appParams.page_no = pageNo
	}
	loading.value = true

	const data = {
		...appParams,
		appTypeList: [activeKey.value],
	}

	axios.post(api.getAppList, data).then((res) => {
		loading.value = false
		tableData.value = res.list.map((item) => ({ ...item, appId: item.id }))
		appParams.total = res.total
		appParams.page_no = res.page_no
		appParams.page_size = res.page_size
		appParams.old_page_size = res.page_size
	})
}

const reset = () => {
	appParams.name = ""
	appParams.appCategory = ""
	appParams.total = 0
	appParams.page_no = 1
	appParams.page_size = 20
	appParams.old_page_size = 20
	getDataList()
}

const handleChangePage = (page, pageSize) => {
	appParams.page_size = pageSize
	appParams.page_no = appParams.old_page_size === pageSize ? page : 1
	getDataList()
}

const changeDiagle = (type, value) => {
	if (type === "info") {
		const data = { appId: value.appId }
		axios.post(api.getMerchantAppDetail, data).then((res) => {
			console.log("getMerchantAppDetail", res)
		})
	}
	dialogVisible.value = !dialogVisible.value
}

const addApp = () => {
	router.push({ name: "AddAppTemplate" })
}

const editApp = (value) => {
	router.push({
		name: "AddAppTemplate",
		state: { json: JSON.stringify(value) },
	})
}

const goToOauth = (record) => {
	router.push({
		name: "createProduct",
		query: {
			appCode: record.code,
			appName: record.name,
			icon: record.icon,
		},
	})
}
</script>

<style lang="scss" scoped>
.app-application {
	background-color: #fff;
	padding: 10px;
	.guest-container {
		min-height: 100%;
	}
	.el-card.is-always-shadow {
		min-height: 100% !important;
	}
	.marginInfoList {
		margin: 10px 0;
		color: #6f7071;
		font-size: 14px;
		.infoLabel {
			text-align: center;
		}
		.infoValue {
			text-align: left;
		}
	}
	.product-card {
		display: flex;
		flex-wrap: wrap;
		.card-item {
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}
}
</style>
